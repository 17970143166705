<template>
    <div class="size print-container">
        <div class="top">
            <img src="@/assets/images/logo/logo.png" class="bg-1" alt="">
            <img src="https://argasolusi.com/assets/images/new_logo.png" class="logo absolute top-20 right-20" alt="">
        </div>
        <div class="center flex justify-center items-center">
            <div class="content" ref="content">
                <!-- Your content goes here -->
                 <img :src="$storageUser + '/' + item.user.picture" class="avatar" alt="">
                <h1 class="name" id="name">{{ item.name }}</h1>
                <h4 class="role">{{ item.position.name }}</h4>
            </div>
            <!-- <button @click="generatePDF()">Generate PDF</button> -->
        </div>
        <div class="bottom mt-10 absolute bottom-20 left-20">
            <ul class="my-10"> 
                <li class="text-2xl">ID Card: {{ item.nik }}</li>
                <li class="text-2xl">Phone: {{ item.mobile1 }}</li>
                <li class="text-2xl">Email: {{ item.email }}</li>
            </ul>
            <vue-qr :bgSrc='src' :logoSrc="src2" :text="`${item.name} | ${item.position.name} | ${item.nik}`" :size="200"></vue-qr>
            <!-- <img src="@/assets/images/qr/front.png" class="w-48 h-48" alt=""> -->
        </div>
        <img src="@/assets/images/logo/bg-2.png" class="bg-2" alt="">
    </div>
</template>



<script>
import {mapGetters} from "vuex";
import VueQr from 'vue-qr'


export default {
  name: 'CardEmployee',
  data() {
    return {
        id: null,
    }
  },    
  methods: {
    getDetail(){
        this.$store.dispatch('employee/detailItem', {id: this.id});
    }
  },
  computed: {
    ...mapGetters({
      item: 'employee/showItem',
    }),
    
    isMe () {
      return this.$store.state.AppActiveUser.employee.nik == this.item.nik;
    },
  },
  components: {
    VueQr
  },
  mounted() {
      
},
created() {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.getDetail();
    document.title = document.getElementById("name").innerHTML
    // setTimeout(() => {
    //     window.print()
    // }, 3000);
  }
}
</script>

<style scoped>
    .size {
        background-color: #fff;
        width: auto; /* Fit to content */
        height: auto; /* Fit to content */
        max-width: 100%; /* Prevent overflow beyond the page */
    }
    .bg-1 {
        transform: rotate(143deg);
        position: absolute;
        width: 1280px;
        z-index: -1;
        top: -340px;
        left: -500px;
    }
    .logo {
        width: 200px;
        height: 200px;  
    }
    .content {
        position: absolute;
        margin: 0;
        top: 250px;
    }
    .content .avatar {
        width: 400px;
        height: 400px;
        border-radius: 50%;
        border: 10px solid #4AB54E;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 9999;
    }
    .name {
        text-align: center;
        margin: 50px 0 20px 0;
        font-weight: 700;
        font-size: 32px;
    }
    .role {
        text-align: center;
        font-weight: 400;
        margin: 0 0 20px 0;
        font-size: 24px;
        margin-bottom: 20px;
        font-style: italic;
    }
    .bg-2 {
        position: absolute;
        width: 400px;
        z-index: -1;
        right: 0;
        bottom: 0px;
        opacity: 0.5;
        filter: brightness(0.8);
    }

    @media print {
      /* Ensure content fits within one page */
      body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
      }

      /* Set size and margins for printing */
      .print-container {
        width: 100%;
        height: 100%;
        page-break-before: avoid;
        page-break-after: avoid;
        page-break-inside: avoid;
        overflow: hidden;
      } 

      /* Hide other elements */
      /* .no-print {
        display: none;
      } */

      /* Ensure no extra content is printed */
      @page {
        size: A4; 
        margin: 0; /* Remove margins */
      }
    }

</style>